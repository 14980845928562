.add_btn {
  min-width: 74px;
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  background: #005AFF;
  color: #fff;
  position: relative;
}

.spin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  :global {
    .ant-spin-dot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.has_icon {
  justify-content: space-between;
  gap: 8px;
}

.add_btn:hover {
  background: linear-gradient(126.38deg, #005AFF 8.81%, #6C28FF 61.41%);
}

.default {
  min-width: auto;
  background: #fff;
  color: #005AFF;
  border: 1px solid #005AFF;
  font-weight: 700;

  svg path {
    stroke: #005AFF;
  }
}

.default:hover {
  background: #fff;
  opacity: 0.8;
}
.add_btn.disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  cursor: not-allowed;

  svg path {
    stroke: #d9d9d9;
  }
}

.add_btn.disabled:hover {
  background: #f5f5f5;
  opacity: 1;
}

@root-entry-name: default;