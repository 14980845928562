.custom-wrapper {
    margin: 20px 0 4px;
    .custom-card {
        background-color: #fff;
        border-radius: 15px;
        overflow: hidden;
        width: 100%;
    }

    .mongo-table {
        height: 100%;
        padding: 10px 10px 0;
        box-sizing: border-box;
        .odd>td {
            background-color: #f7f7f759;
          }
          .even>td {
            background-color: #fff;
          }
        // .ant-table-header{
        //     height: 28px;
        // }
        .ant-table-thead>tr>th {
            background-color: #fff !important;
        //     display: inline-block;
        //     font-weight: 600;
        //     height: 28px;
        //     width: 50%;
        //     font-size: 12px;
        }
        // .ant-table-body>table>.ant-table-tbody>tr:not(:first-of-type){
        //     display: inline-block;
        //     width: 100%;
        //     height: 28px
        // }
        .ant-table-body>table>.ant-table-tbody>tr>td{
        //     padding: 0;
        //     display: inline-block;
        //     width: 50%;
            font-size: 12px;
        //     height: 28px;
        //     line-height: 28px;
        }
        // .ant-table-column-sorters{
        //     width: 100%;
        //     height: 28px;
        //     line-height: 28px;
        // }
        // .ant-table-column-sort{
        //     background-color: #fff;
        // }
        // colgroup{
        //     display: block;
        // }
        // .tr{
        //     display: inline-block;
        //     width: 100%;
        //     height: 28px
        // }
        .ant-table-column-sorter-inner .active{
            color: #005AFF;
        }
        .ant-pagination-item-active{
            background: #A5C3FA5E;
            border: none;
            border-radius: 4px;
            a{
                color: #005AFF;
            }
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
            border-color: #005AFF;
            box-shadow: 0 0 0 2px rgba(0, 90, 255, 0.2);
        }
        .ant-select-item-option-selected{
            background-color: rgba(0, 90, 255, 0.2);
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
            border-color: #005AFF;
        }
        .ant-pagination-item:focus a, .ant-pagination-item:hover a{
            color: #005AFF;
        }
        .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon{
            color: #005AFF;
        }

    }
}

.customcard-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.customcard-title{
    max-width: 80%;
    flex: 1 1 auto;
    color: #000;
    font-family: "PingFang SC", "Segoe UI", "Helvetica Neue", "Arial", "Microsoft YaHei", serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customcard-title-operation {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    .save_icon {
        cursor: pointer;
    }
    .save_icon:hover {
        path {
            fill: #A9AEB2;
        }
    }
}
@root-entry-name: default;