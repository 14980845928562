.chart-foot-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;

    .operate-left {}
}

.opinionType {
    display: flex;
    margin: 0px 0 20px 0;
    font-size: 12px;

    .opinionItem {
        padding: 5px;
        box-sizing: border-box;
        margin-right: 5px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.28);
        border-radius: 5px;
        margin-right: 10px;
    }

    .activeOpinion {
        background: #87ceeb;
    }
}

.opinionFoot {
    margin-top: 20px;
}

.chart-slice {
    height: 100%;
}

.chart-wrapper {
    height: 100%;
}

.fix-card-container {
    padding: 15px;
    flex: 1;
}
@root-entry-name: default;