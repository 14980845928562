.quote_file {
  cursor: pointer;
  user-select: none;
}

.quote_file_grey:hover {
  path {
    fill: #A9AEB2;
  }
}

.select_file_box {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.select_file_item {
  width: 250px;
  height: 69px;
  border-radius: 10px;
  background: #F6F7FF;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  cursor: default;
  position: relative;
}

.close_icon {
  position: absolute;
  right: -6px;
  top: -7px;
  display: none;
  cursor: pointer;
}

.select_file_item:hover .close_icon {
  display: block;
}

.file_icon {
  flex: 0 0 auto;
}

.file_item {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 0;
}

.file_title {
  color: #000;
  font-family: "PingFang SC", "Segoe UI", "Helvetica Neue", "Arial", "Microsoft YaHei", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.file_type {
  color: #BCBECA;
  font-family: "PingFang SC", "Segoe UI", "Helvetica Neue", "Arial", "Microsoft YaHei", serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

@root-entry-name: default;