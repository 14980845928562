.g-flex-1 {
  flex: 1;
}

.g-flex-0 {
  flex: 0;
}

.g-flex {
  display: flex;
}

.g-align-center {
  align-items: center;
}

.g-justify-between {
  justify-content: space-between;
}

.g-justify-right {
  justify-content: right
}

.g-justify-center {
  justify-content: center
}

.g-flex-right {
  .g-flex;
  .g-align-center;
  .g-justify-right
}

.g-flex-between {
  .g-flex;
  .g-align-center;
  .g-justify-between;
}

.g-flex-center {
  .g-flex;
  .g-align-center;
  .g-justify-center;
}

.g-font-12 {
  font-size: 12px;
}

.g-sub-color {
  color: #485e75;
}

.prising {
  width: 100%;
  overflow-y: auto;
}
.header {
  position: relative;
  box-sizing: border-box;
  background-size:cover ;
}

.logo {
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: inherit;
  z-index: 10;
  background-color: #fff;
}

.topLeftContentImg{
  position: absolute;
  left: 0;
  top: 40px;
}

.menu {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.menu_btn {
  cursor: pointer;
  padding: 8px;
  box-sizing: border-box;
  color: #21272A;
  margin-left: 16px;
  position: relative;
  font-size: 16px;
  &:hover {
    color:#2554fe
  }
}

.menu_btn_active{
  color:#005AFF;
  &::after {
    content: ' ';
    display: inline-block;
    width: 50%;
    height: 4px;
    border-radius: 4px;
    position: absolute;
    top: 33px;
    bottom: 0;
    left: 50%;
    right: 0;
    opacity: 1;
    background-color: #005AFF;
    transform: translateX(-50%);
    transition: all 0.3s;
  }
}

.menu_login{
  margin-left: 80px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid var(--darkblue, #005AFF);
  background-color: transparent;
  color: #005AFF;
}
.menu_free{
  cursor: pointer;
  margin-left: 20px;
  border-radius: 6px;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #005AFF;
  color: #fff;
}

.pricing_container {
  position: relative;
  height: calc(100vh - 110px);
  .g-flex;
  .g-align-center;
  flex-direction: column;
  background: linear-gradient(126deg,#EDF4FF,#EDEFFF,#EEECFF);
}

.title {
  letter-spacing: 1px;
  font-size: 32px;
  padding: 40px 0 20px;
  text-shadow: 0 0 black;
}

.login_experience, .buy {
  padding: 16px 0;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 1;
  width: 70%;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.login_experience {
  border: 1px solid #005AFF;
  background: linear-gradient(121.16deg, #EDF4FF 14.48%, #EEECFF 81.57%);
  span {
    display: inline-block;
    background-image: linear-gradient(126.38deg, #005AFF 38.81%, #6C28FF 61.41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:hover {
    opacity: 0.7;
  }
}

.ad_performance {
  position: absolute;
  top: 186px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  height: 30px;
  padding: 5px 20px;
  border-radius: 10px;
  background: #F76464;
}

.buy_bottom_tip {
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}
.free_bottom_tip {
  font-weight: bold;
  margin-bottom: 8px;
  color: #FC3124;
  text-align: center;
}

.buy {
  background: linear-gradient(123.73deg, #005AFF 20.95%, #6C28FF 100%);
  &:hover {
    opacity: 0.7;
  }
}

.pricing_content {
  margin-top: 40px;
  .g-flex;
  gap: 32px;
}

.pricing_box {
  padding: 20px 20px 40px;
  border-radius: 15px;
  width: 280px;
  height: 600px;
  letter-spacing: 1px;
  .g-flex;
  .g-justify-between;
  flex-direction: column;
}

.header_title {
  font-size: 24px;
  text-shadow: 0 0 black;
  text-align: center;
  margin-bottom: 6px;
  font-weight: bold;
}

.check_out_line {
  font-size: 12px;
  color: #0ecfb8;
  font-weight: bold;
  margin-right: 6px;
}

.header_sub {
  text-align: center;
  font-size: 12px;
  text-shadow: 0 0 0 #fff;
}

.price {
  margin-top: 12px;
  position: relative;
  margin-bottom: 80px;
  text-align: center;
}

.unit {
  font-size: 14px;
  position: absolute;
  bottom: 8px;
  left: 0;
}

.money {
  position: relative;
  padding-left: 12px;
  font-size: 42px;
  font-weight: bold;
  display: inline-block;
}

.per_unit {
  font-size: 12px;
  position: absolute;
  right: -20px;
  bottom: 0;
  text-align: center
}

.item_list {
  .g-flex;
  .g-align-center;
  line-height: 28px;
  text-shadow: 0 0 0 #fff;
}

.item_words {
  text-shadow: 0 0 black;
}

.free_version {
  background: rgba(255,255,255,.9);
  color: #333;
  box-shadow: 0px 4px 21px 0px #0000000D;
}

.std_version {
  position: relative;
  color: #fff;
  background: linear-gradient(290deg, #B2BFFF -1.05%, #F6F7FF -1.05%, #F6F7FF -1.05%, #F9B5FF -1.04%, #4967FF 84.91%);
  box-shadow: 0px 4px 21px 0px #0000000D;
}

.ori_price {
  position: absolute;
  color: #fff;
  font-size: 15px;
  margin-top: 2px;
  width: 100%;
  text-align: center;
  text-decoration: line-through;
}

.stars {
  position: absolute;
  right: -2px;
  top: -2px;
  color: #fff;
  font-size: 12px;
  border-right: 60px solid #F0B80D;
  border-bottom: 60px solid transparent;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  span {
    position: absolute;
    top: 12px;
    right: -56px;
    white-space: nowrap;
    transform: rotate(45deg);
  }
}

.cus_limit {
  position: absolute;
  right: -2px;
  top: -2px;
  color: #fff;
  font-size: 12px;
  border-right: 80px solid #f76464;
  border-bottom: 80px solid transparent;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  span {
    position: absolute;
    top: 24px;
    right: -76px;
    white-space: nowrap;
    transform: rotate(45deg);
  }
}

.form_item {
  position: relative;
  .g-flex-between;
  line-height: 40px;
}

.form_item_label {
  font-weight: bold;
}

.form_total {
  .red;
}
.red {
  color: #ff4d4f;
}

.form_item_el {
  margin-bottom: 0;
  transform: translateX(16px);
}

.order_container {
  background-color: #fff;
  margin: 40px 80px;
  padding: 20px;
  border-radius: 15px;
}

.order_title {
  color: #333;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.order_number {
  padding: 20px;
}

.order_detail {
  margin-bottom: 20px;
  border: 1px solid #f3f3f3;
}

.order_head {
  background-color: #fafafa;
  padding: 20px 30px;
  border-bottom: 1px solid #f3f3f3;
  .g-flex-between;
}

.order_desc {
  font-size: 14px;
  font-weight: bold;
}

.order_pay_zone {
  .g-flex;
  .g-justify-between;
  padding: 20px 30px;
  border: 1px solid #f3f3f3;
}

.order_price {
  font-size: 24px;
  color: #ff4d4f;
  font-weight: bold;
}

.order_qrcode {

}

.order_pay_way_desc {
  margin-bottom: 6px;
}

.order_error {
  .g-flex;
  align-items: end;
  font-size: 12px;
}

.order_pay_way {
  .g-flex;
  align-items: baseline;
  flex-direction: column;

}

.adtomic_btn{
  margin-left: 10px;
  background: #005AFF;
  color:#fff;
  padding: 0;
  width: 100px;
  text-align: center;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: none!important;
  border-color: #005AFF!important;
}

.adtomic_btn:hover, .adtomic_btn:focus{
  background: linear-gradient(126.38deg, #005AFF 8.81%, #6C28FF 61.41%);
}

.custom_container {
  .g-flex;
  flex-direction: column;
}

.top_cus_version, .bottom_cus_version {
  position: relative;
  border-radius: 15px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  .g-flex;
  .g-justify-between;
  flex-direction: column;
  padding: 20px 20px 40px;
  border-radius: 15px;
  width: 280px;
  letter-spacing: 1px;
  background-color: #fff;
}

.cus_top_content {
  margin-top: 60px;
  font-size: 12px;
  margin-bottom: 20px;
  line-height: 1.4;
  text-align: center;
  .g-flex;
  flex-direction: column;
  text-shadow: 0 0 black;
}

.bottom_cus_version {
  margin-top: 20px;
  .g-flex-1;
  padding: 0;
  overflow: hidden;
}

.bottom_cus_head {
  background: linear-gradient(116.32deg, #DAEBFF 8.92%, #EEECFF 80.39%);
  height: 86px;
  padding: 20px 40px;
  .g-flex;
  .g-align-center;
}

.bottom_cus_ai_assis {
  color: #005AFF;
  font-size: 24px;
  margin-left: 30px;
}

.bottom_cus_content {
  padding: 24px;
  line-height: 1.8;
  text-shadow: 0 0 black;
}

.red_word {
  color: #F76464;
}

.red_word_limit {
  .red_word;
  font-size: 16px;
}

.red_word_origin {
  .red_word;
  font-size: 22px;
}

.bottom_cus_footer {
  font-size: 20px;
  padding: 0 20px 40px;
}

@root-entry-name: default;