.aiSave{
  padding:0 30px 30px 30px;
  box-sizing: border-box;
  .tips{
    width: 100%;
    height: 25px;
    // background: #00EEA2;
    color: #000;
    background-color: rgba(0, 238, 162,0.1);
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    svg{
      margin: 0 10px;
    }
    span{
      color: #005AFF;
    }
  }
  .footer{
    width: 100%;
    height: 40px;
    margin: 0 auto;
    .footer_btnGroup{
      width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div{
      color: #fff;
      border-radius: 8px;
      padding: 10px 30px;
      cursor: pointer;
    }
    .cancel{
      color:#fff;
      background: #F76464;
    }
    .cancel:hover{
      background: #D84B4B;
    }
    .saveAndTo{
      color: #5046E5;
      background: #E0E7FF;
    }
    .saveAndTo:hover{
      color: #fff;
      background: #88A9E7;
    }
    .btnDisabled{
      cursor: not-allowed;
      background: #DEE0E9;
      color: #5D5C5C;
    }
    .save{
      color:#fff;
      background: #005AFF;
    }
    .save:hover{
      // background: #0352E1;
      background: linear-gradient(126.38deg, #005AFF 8.81%, #6C28FF 61.41%);
    }
    }
  }
}
.aiDialog{
  .ant-modal-header{
    border: none;
    padding: 30px 30px 10px 30px;
  }
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-body{
    padding:0;
  }
  .ant-modal-close-x{
    position: absolute;
    right: 10px;
    top: 13px;
    .closeIcon{
      // background-color:rgba(255, 112, 129, 0.05);
      width: 40px;
      height: 40px;
      font-size: 20px;
      line-height: 40px;
      border-radius: 50%;
      // color:rgba(255, 112, 129, 0.8);
      text-align: center;
    }
  }
  .ant-radio-group{
    width: 100%;
    display: flex;
    .ant-radio-wrapper{
      width: 50%;
      color:#9494A3
    }
    .ant-radio-wrapper-checked{
      color:#030229
    }
  }
  .ant-modal-title{
    font-size: 20px;
    font-weight: 600;
  }
  .ant-form{
    margin-bottom: 30px;
  }
  .ant-form-item-label{
    label{
      font-size: 16px;
      color: #030229;
    }
  }
  .ant-form-item-control-input-content{
    input{
      height: 50px;
    }
  }
  .ant-select-selection-search-input{
    height: 50px!important;
  }
  .ant-select-selection-placeholder{
    line-height: 50px!important;
  }
  .ant-select-selector{
    height: 50px!important;
  }
  .ant-select-selection-item{
    line-height: 50px!important;
  }
}


@root-entry-name: default;