.ant-btn-primary  {
  border-color: #005aff;
  background-color: #005aff;
  &:hover {
    border-color: #3071ec;
    background-color: #3071ec;
  }
}
.ant-btn-default  {
  border-color: #005aff;
  color: #005aff;
  &:hover {
    border-color: #3071ec;
    color: #3071ec;
  }
}

@root-entry-name: default;