body, #app, #app-menu {
    font-family: "PingFang SC", "Segoe UI", "Helvetica Neue", "Arial", "Microsoft YaHei", serif;
}
a,
.ant-tabs-tab:hover{
    color: #005AFF;
  }
  .ant-input{
    border-radius: 5px;
  }
  .ant-input:hover{
    border-color: #00EEA2;
  }
  .ant-input-focused, .ant-input:focus{
    border-color: #00EEA2;
    box-shadow: 3px 4px 4px 0px #00EEA20F;
  }
  .ant-select-selector{
    border-radius: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #00EEA2;
    box-shadow: 3px 4px 4px 0px #00EEA20F;
}
.ant-select-item-option-selected{
    background: #F7F8FF;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background: #F7F8FF;
}
.ant-tooltip-inner {
    a{
        color: #fff!important;
    }
}
.ant-radio-inner::after{
  top:4px;
  left:4px;
  width: 7px;
  height: 7px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #005AFF;
  border-color: #005AFF;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #005AFF;
}
@root-entry-name: default;