.auth-start-container {
  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0;
    text-align: left;
  }
  .tips {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #5D5C5C;
  }
  .auth-success {
    font-size: 20px;
    font-style: normal;
    text-align: center;
  }
  .auth-success-tips {
    width: 626px;
    margin: 0 auto;
    margin-top: 45px !important;
    padding-right: 14px !important;
  }
  .main-container {
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    margin-top: 56px;

    .img-container {
      width: 395px;
      height: 409px;
      background-color: #ccc;
      margin-right: 45px;
    }
    .main-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .accout {
        position: relative;
        .show-count {
          position: absolute;
          right: 11px;
          bottom: 23px;
          z-index: 10;
          color: #919191;
        }
      }
    }
    .form-item {
      padding-bottom: 19px;
      .label-name {
        font-size: 16px;
        font-weight: 600;
      }
      .red-star {
        color: #F90C0C;
      }
      .input-common {
        width: 100%;
        border-color: #919191;
        border-radius: 4px;
      }

      .auth-select {
        width: 100%;
        border-radius: 5px;
        border: 0;

        .ant-select-selector {
          border: 0;
          border-radius: 5px;
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #005AFF;
        border-color: #005AFF;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: #005AFF;
      }
    }
  }
  .tips-container {
    padding: 14px 0;
    // border-left: 3px solid #4B25EA;
    background: #C0D5FC;
    border-radius: 8px;
    .warn-tips {
      width: 55px;
    }
  }
  .foot-form{
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .auth-tips {
      color: #030229;
      display: inline-block;
      margin-top: 30px;
      font-size: 14px;
      font-weight: 600;
    }

    .auth-btn {
      width: 132px;
      height: 45px;
      padding: 10px 30px;
      border-radius: 8px;
      margin-top: 20px;
      background: linear-gradient(123.73deg, #005AFF 20.95%, #6C28FF 100%);
    }
  }
}
.author-func {
  .func-contain {
    .func-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0;
      text-align: left;
    }
    
    .func-panel {
      background-color: #F6F7FF;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 15px;
      margin-top: 20px;

      .func-panel-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
      }

      .func-panel-tips {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #5D5D5D;
        margin-top: 5px;
        padding-right: 10px;
      }

      .func-url {
        background-color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        color: #7A7A7B;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 13px 50px 13px 13px;
        width: 480px;
        margin-top: 8px;
      }
      
      .auth-btn {
        background-color: #E0E7FF;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0;
        text-align: center;
        color: #5046E5;
        border: 0;
        border-radius: 5px;
      }
    }
  }
  .ant-modal-footer {
    border: none;
  }
  .func-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    .footer-btn {
      background: linear-gradient(123.73deg, #005AFF 20.95%, #6C28FF 100%);
      border-radius: 8px;
      border: none;
      padding-left: 30px;
      padding-right: 30px;
      font-weight: 600;
    }
  }
}
.authDialog{
  .ant-modal-content{
    background: linear-gradient(308.57deg, #F6F9FF -1.05%, #F6F7FF -1.05%, #F6F7FF -1.05%, #D1E3FF -1.04%, #F2F9FF 84.91%);
  }
}

@root-entry-name: default;