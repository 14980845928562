.ai_helper_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-right: 12px;
  transform: scale(0.8);
}

.ai_helper_button:hover {
  opacity: 0.8;
}

@root-entry-name: default;