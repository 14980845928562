.mask-content {
  position: relative;
  overflow: hidden;

  .mask-warpper {
    background: rgba(255, 255, 255, 0.37);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    backdrop-filter: blur(2px);
  }

  .mask-container {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    width: 424px;

    .title {
      font-size: 40px;
      text-align: center;
    }

    .content {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0;
    }

    .go-auth {
      width: 400px;
      height: 50px;
      font-size: 20px;
      font-weight: bold;
      background-color: #215afe;
      color: white;
      line-height: 50px;
      text-align: center;
      border-radius: 12px;
      margin-top: 70px;
      cursor: pointer;
    }

    .go-demo {
      width: 400px;
      height: 50px;
      font-size: 20px;
      font-weight: bold;
      border: 1px solid #8FA2FF;
      line-height: 50px;
      text-align: center;
      border-radius: 12px;
      margin-top: 30px;
      background-image: linear-gradient(308.57deg, #B2BFFF -1.05%, #F6F7FF -1.05%, #F6F7FF -1.05%, #F9B5FF -1.04%, #4967FF 84.91%);
      color: transparent;
      cursor: pointer;
      background-clip: text;
    }
  }

  .adtopic .menu {
    top: 0;
  }
}
@root-entry-name: default;