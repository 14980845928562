.maskContent {
  .maskWarpper {
    background: rgba(255, 255, 255, 0.37);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    backdrop-filter: blur(2px);
  }

  .maskContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 100;
    width: 460px;
    height: 260px;
    box-shadow: -14px 30px 20px 0px #0000000D;
    border-radius: 10px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;


    .title {
      margin: 0;
    }
  }

  .bottom {
    flex: 0 0 auto;
    padding-bottom: 32px;
  }

  .goBuy {
    margin: 0 auto;
    width: 140px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
  }
}

@root-entry-name: default;