.custom_spin_icon {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 44px;
  margin: 0 !important;
  transform: translate(-50%, -50%) !important;
}

@keyframes light_translate {
  0% {
    transform: translateX(-8px);
  }
  25% {
    transform: translateX(0) scale(0.9);
    z-index: -1;
  }
  50% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(0);
    z-index: 1;
  }
  100% {
    transform: translateX(-8px);
  }
}

@keyframes dark_translate {
  0% {
    transform: translateX(8px);
  }
  25% {
    transform: translateX(0);
    z-index: 1;
  }
  50% {
    transform: translateX(-8px);
  }
  75% {
    transform: translateX(0) scale(0.9);
    z-index: -1;
  }
  100% {
    transform: translateX(8px);
  }
}

.custom_spin_light {
  position: absolute;
  animation: light_translate 1.2s linear infinite;
}

.custom_spin_dark {
  position: absolute;
  animation: dark_translate 1.2s linear infinite;
}

@root-entry-name: default;