.pay_btn, .cancel_btn {
  cursor: pointer;
  font-size: 14px;
  &:hover {
    opacity: 0.7;
  }
}

.pay_btn {
  color: #005aff;
}

.cancel_btn {
  color: #333;
}

.my_order_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
 .my_order_container {
  padding: 20px;
  background-color: #fff;
 }

 .g_flex_global {
  display: flex;
  align-items: center;
  gap: 12px;
 }

@root-entry-name: default;