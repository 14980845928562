.auth-start-container {
  .title {
    text-align: left;
    font-size: 28px;
    padding-left:24px;
    font-style: normal;
    font-weight: 600;
  }
  .tips {
    text-align: left;
    padding-left:24px;
  }
  .auth-success {
    font-size: 20px;
    font-style: normal;
    text-align: center;
  }
  .auth-success-tips {
    width: 626px;
    margin: 0 auto;
    margin-top: 45px !important;
    padding-right: 14px !important;
  }
  .main-container {
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    margin-top: 56px;

    .img-container {
      width: 395px;
      height: 409px;
      background-color: #ccc;
      margin-right: 45px;
    }
    .main-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .accout {
        position: relative;
        .show-count {
          position: absolute;
          right: 11px;
          bottom: 23px;
          z-index: 10;
          color: #919191;
        }
      }
    }
    .form-item {
      padding-bottom: 19px;
      .lable-name {
        font-size: 16px;
        font-weight: 600;
      }
      .red-star {
        color: #F90C0C;
      }
      .input-common {
        width: 100%;
        border-color: #919191;
        border-radius: 4px;
      }
    }
  }
  .tips-container {
    padding: 14px 0;
    // border-left: 3px solid #4B25EA;
    background: #C0D5FC;
    border-radius: 8px;
    .warn-tips {
      width: 55px;
    }
  }
  .foot-form{
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.authDialog{
  .ant-modal-content{
    background: linear-gradient(308.57deg, #F6F9FF -1.05%, #F6F7FF -1.05%, #F6F7FF -1.05%, #D1E3FF -1.04%, #F2F9FF 84.91%);
  }
}

@root-entry-name: default;